.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.App-logo {
  width: 600px;
  max-width: 100%;
}

.discord_shout {
  text-align: center;
}

.discord-logo {
  width: 200px;
  max-width: 100%;
  padding: 0px 32px;
}

.spacer-v16 {
  height: 16px;
  display: block;
}

.comp-links-grid {
  margin: 0px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 600px;
  max-width: 100%;
  img {
    width: 180px;
  }
  p {
    margin: 4px 0;
  }
}

.complink {
  padding: 8px;
  .logo-link {
    min-height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cedapug-logo {
  background-color: orange;
}